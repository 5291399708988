import { withDependencies } from '@wix/thunderbolt-ioc'
import { Experiments, ExperimentsSymbol, TpaHandlerExtras } from '@wix/thunderbolt-symbols'
import { SiteMembersApiSymbol } from './symbols'
import { ISiteMembersApi, CurrentMemberTPAHandlerResponse, TPARequestLoginOptions, TPALogoutOptions } from './types'

const siteMembersTPAHandlers = (siteMembersApi: ISiteMembersApi, experiments: Experiments) => ({
	getTpaHandlers() {
		const currentMember = async (
			_compId: never,
			_data: never,
			extras: TpaHandlerExtras
		): Promise<CurrentMemberTPAHandlerResponse> => {
			const member = await siteMembersApi.getMemberDetails()
			if (member) {
				if (!extras.appClientSpecMapData?.isWixTPA && experiments['specs.thunderbolt.removePiiFromSMTPAs']) {
					return {
						id: member.id,
						owner: member.owner,
						status: member.status,
					}
				} else {
					return {
						attributes: {
							firstName: member.firstName ?? '',
							lastName: member.lastName ?? '',
							privacyStatus: member.profilePrivacyStatus,
						},
						name: member.memberName,
						email: member.loginEmail,
						id: member.id,
						owner: member.owner,
						status: member.status,
					}
				}
			}

			return null
		}
		const requestLogin = async (
			_compId: void,
			dialogOptions: TPARequestLoginOptions = {}
		): Promise<CurrentMemberTPAHandlerResponse> => {
			const { member } = await siteMembersApi.promptLogin({
				mode: dialogOptions.mode,
				modal: dialogOptions.modal,
			})
			return {
				attributes: {
					firstName: member.firstName ?? '',
					lastName: member.lastName ?? '',
					privacyStatus: member.profilePrivacyStatus,
				},
				name: member.memberName,
				email: member.loginEmail,
				id: member.id,
				owner: member.owner,
				status: member.status,
			}
		}

		return {
			currentMember,
			smCurrentMember: currentMember,
			logOutCurrentMember(_comp: never, options?: TPALogoutOptions): void {
				siteMembersApi.logout(options?.url)
			},
			requestLogin,
			smRequestLogin: requestLogin,
		}
	},
})

export const SiteMembersTPAHandlers = withDependencies(
	[SiteMembersApiSymbol, ExperimentsSymbol],
	siteMembersTPAHandlers
)
